@import url('https://fonts.googleapis.com/css2?family=PT+Sans&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  scroll-behavior: smooth;
  position: relative;
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
  color: inherit !important;
}

::selection {
  background-color: var(--primary-color--);
  color: #fff;
}
